import * as React from "react"
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionShowreelGroup = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 30px 30px 20px 30px;
`;

export default function UiUxpage() {
  return (
    <Layout>
    <Seo title="Ui Ux And Apps" />
    <div className="Hero">
      <div className="HeroGroup">
    
        <SectionShowreelGroup>
          <h2>Ui and Apps</h2>
        </SectionShowreelGroup>
      </div>
    </div>
    </Layout>
  );
}
